import "bootstrap/js/src/alert";
import atomic from "atomicjs";
import "../../styles/components/_Form.scss";
import Component from "./_base";
import { lang } from "../config";

// N.B.: this component is used both by the Contact Form and by the Career Form
export default class Form extends Component {
  static rootSelector = ".Form";

  // country list insert in contact form
  // https://restcountries.eu/rest/v2/all?fields=name;translations
  countryList() {
    // restcountries does not work anymore, replaced with fillform dedicated form with inputlist nations
    // atomic("https://restcountries.eu/rest/v2/all?fields=name;translations")
    atomic(
      "https://api.fillform.io/205427b9fc622e3b75f160314f34738f/form/single/384"
    )
      .then(function(response) {
        // console.log(response.data.fields[0].option);
        const country = response.data.fields[0].option;
        let option = "";
        for (let x = 0; x < country.length; x++) {
          // console.log(country[x].key);
          option +=
            '<option value="' +
            country[x].key +
            '">' +
            country[x].key +
            "</option>";
        }
        const select = document.getElementById("cfcountry");
        select.innerHTML += option;
        // console.log(option);
      })
      .catch(function(error) {
        console.log(error.status); // xhr.status
        console.log(error.statusText); // xhr.statusText
      });
  }

  $onReady() {
    this.$inputHidden = $("#cfpageurl"); // this.$root.find("[name='cfpageurl']");
    this.$inputHidden2 = $("#inputcareerPageurl"); // this.$root.find("[name='inputcareerPageurl']");
    const { pathname } = window.location;

    this.$inputHidden.val(pathname);
    this.$inputHidden2.val(pathname);

    this.countryList();
  }
}

new Form();
