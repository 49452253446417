import $ from "jquery";
import "slick-carousel";
import { initSlider } from "../vendor/slick";
import { lazyOptions } from "../vendor/lazy";
import Component from "./_base";

export default class MenuDesktopMega extends Component {
  static rootSelector = ".MenuDesktopMega";

  $onInit() {
    this.currentItemIdx = 0;
    this.sliderInitialised = false;
    // this.isHoveringList = false;
  }

  $onReady() {
    this.$slider = this.$root.find("[data-js='slider']");
    // this.$list = this.$root.find("[data-js='list']");
    // this.$items = this.$list.find("[data-js='item']");
    this.$hover = this.$root.find("[data-js='hover']");

    // this.$list
    // .on("mouseover", () => {
    //   this.onMouseOver();
    // })
    // .on("mouseleave", () => {
    //   this.onMouseLeave();
    // });

    // this.$items.each((idx, item) => {
    //   $(item).on("mouseover touchstart", () => {
    //     this.slider();
    //     this.setCurrent(idx);
    //     this.$slider.slick("slickGoTo", idx);
    //   });
    // });

    this.$root.on("mouseleave", () => {
      // console.log(this.$root);
      this.$hover.each((idx, item) => {
        $(item).removeClass("is-visible");
      });
    });

    this.$hover.each((idx, item) => {
      $(item).on("mouseover", () => {
        this.$hover.each((idx, item) => {
          $(item).removeClass("is-visible");
        });
        $(item).addClass("is-visible");
      });
      // $(item).on("mouseleave", () => {
      //   $(item).removeClass("is-visible");
      // });
    });
  }

  slider() {
    if (this.sliderInitialised) {
      return;
    }
    // this.$slider
    //   .find(".img")
    //   .Lazy({ ...lazyOptions, visibleOnly: false, delay: 1 });

    initSlider(this.$slider, {
      arrows: false,
      waitForAnimate: false,
      swipe: false,
      touchMove: false
    });

    this.sliderInitialised = true;
  }

  setCurrent(slideIdx) {
    this.unsetCurrent();
    this.currentItemIdx = slideIdx;
    $(this.$items[slideIdx]).addClass("current");
  }

  unsetCurrent() {
    this.currentItemIdx = 0;
    // this.$items.removeClass("current");
  }

  onOpen() {
    // this.$root.removeClass("is-hover");
    this.unsetCurrent();
  }

  // onMouseOver() {
  //   this.isHoveringList = true;
  //   this.$root.addClass("is-hover");
  // }

  // onMouseLeave() {
  //   this.isHoveringList = false;
  //   setTimeout(() => {
  //     if (!this.isHoveringList) {
  //       this.onOpen();
  //     }
  //   }, 5);
  // }
}
