import $ from "jquery";
import { $html } from "../config";
import Component from "./_base";

export default class MenuMobileLang extends Component {
  static rootSelector = ".MenuMobile";

  $onInit() {
    this.isOpen = false;
    this.classOpen = "MenuMobileLang--open";
    this.classToggler = "MenuMobileLang__toggler";
    this.classActive = "active";
  }

  $onReady() {
    this.$toggler = this.$root.find(`.${this.classToggler}`);

    this.bindEvents();
  }

  open(target) {
    this.isOpen = true;
    $html.addClass(this.classOpen);
  }

  close() {
    this.isOpen = false;
    $html.removeClass(this.classOpen);
  }

  toggle(target) {
    if (this.isOpen) {
      this.close();
    } else {
      this.open(target);
    }
  }

  bindEvents() {
    const { $root, classToggler, classActive } = this;

    // toggler button
    this.$toggler.on("click", () => {
      this.toggle();
      this.$toggler.toggleClass("active");
    });

    // lang menu toggler closes this menu
    $root.find(`button:not(.${classToggler})`).click(event => {
      this.close();
      // this.$toggler.toggleClass("active");
    });

    // all other links closes the menu
    $root.find(`li > a`).click(event => {
      this.close();
    });
  }
}
