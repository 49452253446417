import $ from "jquery";
import { globalConf, $htmlBody, breakpoints, HEADER_SELECTOR } from "../config";

/**
 * Get website base url
 */
export const getBaseUrl = (trailingSlash = true) => {
  let url = globalConf.baseUrl || "";
  if (!url) {
    console.warn("Failed to retrieve baseUrl from `globalConf`");
    return "";
  }

  // ensure trailing slash
  if (trailingSlash) {
    url = url + "/";
  }

  // replace too many end slashes
  return url.replace(/\/+$/, "/");
};

/**
 * Get endpoint url for ajax requests
 */
export const getEndpoint = (path = "", trailingSlash = true) => {
  let url = getBaseUrl() + "/" + path;

  // ensure trailing slash
  if (trailingSlash) {
    url = url + "/";
  }

  // replace too many consecutive slashes (except `http{s}://`)
  return url.replace(/([^:]\/)\/+/g, "$1");
};

/**
 * Scroll to given selector. It kees into account the fixed header offset on
 * mobile.
 */
export const scrollTo = (selector, offset, callback = $.noop) => {
  let top = 0;
  const $target = selector ? $(selector) : null;

  if ($target && $target.length) {
    const $targetOffset = $target.offset();
    top = $targetOffset ? $targetOffset.top : 0;
  }

  if (window.innerWidth < breakpoints.mobileMenu) {
    top += -$(HEADER_SELECTOR).height();
  }

  if (offset) {
    top += offset;
  }

  $htmlBody.animate({ scrollTop: top }, 300, callback);
};

/**
 * Get clean query parameters from URL
 */
export const getQueryParams = () => {
  let params = {};
  const search = location.search.substring(1);

  try {
    // @see https://stackoverflow.com/a/8649003/1938970
    let paramsAsObj = `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`;
    params = JSON.parse(paramsAsObj, (key, value) => {
      return key === "" ? value : decodeURIComponent(value);
    });
  } catch (e) {}

  return params;
};

/**
 * Validate email
 */
export const validateEmail = input => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(input).toLowerCase());
};
