import $ from "jquery";
import Component from "./_base";
import { HEADER_HEIGHT } from "../config";
import { scrollTo } from "../utils";

class SmoothScroll extends Component {
  static rootSelector = "[data-smooth]";

  $onReady() {
    this.$root.each((idx, link) => {
      const $link = $(link);
      let offset = $link.attr("data-smooth-offset") || `${HEADER_HEIGHT}`;
      const isNegative = offset.indexOf("-") === 0;
      if (isNegative) {
        offset = parseInt(offset, 10);
      } else {
        offset = -parseInt(offset, 10);
      }

      $link.on("click", event => {
        event.preventDefault();
        scrollTo(link.hash, offset);
      });
    });
  }
}

new SmoothScroll();
