import "../../styles/components/_AppHeader.scss";
import Component from "./_base";
import { $window } from "../config";

export default class AppHeader extends Component {
  static rootSelector = ".AppHeader";

  $onInit() {
    this.isSticked = this.shouldStick();
  }

  $onReady() {
    this.element = this.$root[0];

    // check immediately
    this.maybeStick();

    // re-check on scroll
    $window.scroll(this.maybeStick.bind(this));
  }

  shouldStick() {
    return (window.pageYOffset || document.documentElement.scrollTop) > 100;
  }

  /**
   * Performs the least DOM operations using a flad
   */
  maybeStick() {
    const wasSticked = this.isSticked;
    const shouldStick = this.shouldStick();

    if (wasSticked !== shouldStick) {
      if (shouldStick) {
        this.isSticked = true;
        this.element.classList.add("is-sticked");
      } else {
        this.isSticked = false;
        this.element.classList.remove("is-sticked");
      }
    }
  }
}

new AppHeader();
