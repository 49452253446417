import $ from "jquery";
import "jquery-lazy/jquery.lazy";
import "jquery-lazy/plugins/jquery.lazy.iframe.js";
import { $document, CLASS_LAZY_ERROR, CLASS_LAZY_SUCCESS } from "../config";

// @see http://jquery.eisbehr.de/lazy/
export const lazyOptions = {
  visibleOnly: true,
  effect: "fadeIn",
  effectTime: 200,
  afterLoad: function($element) {
    $element.addClass(CLASS_LAZY_SUCCESS);
  },
  onError: function($element) {
    $element.parent().addClass(CLASS_LAZY_ERROR);
  }
};

$document.ready(() => {
  $(".lazy")
    .show()
    .Lazy(lazyOptions);
});
