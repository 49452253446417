import $ from "jquery";
import Component from "./_base";
import "jquery-lazy/jquery.lazy";
import "../../styles/components/_VideoYouTube.scss";
import { CLASS_LAZY_SUCCESS } from "../config";

// about Cookiebot @see https://www.cookiebot.com/it/developer/
class VideoYouTube extends Component {
  $onInit() {
    // this.apiKey = "bb6f143c-3bdb-4611-943d-f65d14f7c83f";
    this.canPlay = false;
    this.pendingPlayAction = false;
    this.setStatus(false);
  }

  $onReady() {
    this.$playBtn = this.$root.find("[data-js='play']");
    this.$iframe = this.$root.find("iframe");
    this.videoSrc = this.$root.attr("data-video");

    this.addApiListeners();
  }

  addApiListeners() {
    window.addEventListener(
      "CookiebotOnLoad",
      () => {
        if (window.Cookiebot.consent.marketing) {
          this.canPlay = true;
          this.setStatus(true);
          this.loadVideo();
        }
        this.bindEvents();
      },
      false
    );

    window.addEventListener(
      "CookiebotOnAccept",
      () => {
        if (window.Cookiebot.consent.marketing) {
          this.canPlay = true;
          if (this.pendingPlayAction) {
            this.setStatus(true);
            this.loadVideo();
          }
        } else {
          this.setStatus(false);
        }
      },
      false
    );

    window.addEventListener(
      "CookiebotOnDecline",
      () => {
        if (!window.Cookiebot.consent.marketing) {
          this.setStatus(false);
        }
      },
      false
    );
  }

  bindEvents() {
    this.$playBtn.on("click", () => {
      if (this.canPlay) {
        this.setStatus(true);
        this.loadVideo();
      } else {
        this.setStatus(false);
        this.pendingPlayAction = true;
        window.Cookiebot.renew();
      }
    });
  }

  setStatus(canPlay) {
    this.$root.toggleClass("can-play", canPlay);
  }

  loadVideo() {
    const fullSrc = `https://www.youtube.com/embed/${this.videoSrc}`;
    this.$iframe.attr("src", fullSrc).on("load", () => {
      this.$iframe.addClass(CLASS_LAZY_SUCCESS);
      this.$root.addClass("is-ready");
    });
  }

  // loadApi(callback) {
  //   const script = document.createElement("script");
  //   const firstScript = document.getElementsByTagName("script")[0];
  //   script.id = "Cookiebot";
  //   script.src = "https://consent.cookiebot.com/uc.js";
  //   script.setAttribute("data-cbid", this.apiKey);
  //   script.addEventListener(
  //     "load",
  //     function(e) {
  //       callback(null, e);
  //     },
  //     false
  //   );
  //   firstScript.parentNode.insertBefore(script, firstScript);
  // }
}

$(".VideoYouTube").each((idx, element) => {
  new VideoYouTube(element);
});
