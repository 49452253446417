import $ from "jquery";

window["$"] = $; // for form-builder

window["jQuery"] = $;

export const globalConf = window.__CONFIG || {};

export const $html = $("html");

export const $htmlBody = $("html, body");

export const $body = $("body");

export const $window = $(window);

export const $document = $(document);

export const HEADER_SELECTOR = ".AppHeader";

export const HEADER_HEIGHT = 70;

export const CLASS_LAZY_ERROR = "lazy-error";

export const CLASS_LAZY_SUCCESS = "lazy-success";

const bootstrapBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export const breakpoints = {
  ...bootstrapBreakpoints,
  mobile: bootstrapBreakpoints.md,
  mobileMenu: bootstrapBreakpoints.xl,
  desktop: bootstrapBreakpoints.lg
};

// for format use one of the format strings found in the `date-fns` js plugin
// listed here: https://date-fns.org/v1.29.0/docs/format.
// This value could be read from a `window.globalObject` for convenience
export const dateFormat = "DD-MM-YYYY";

// for internationalization we use a daterange picker js plugin, see the
// language codes options you can use in the plugin docs: https://git.io/fNQb7
// This value could be read from a `window.globalObject` for convenience
export const locale = "en-GB";

export const lang = document
  .getElementsByTagName("html")[0]
  .getAttribute("lang");
