import $ from "jquery";
import "slick-carousel";
import { CLASS_LAZY_ERROR, CLASS_LAZY_SUCCESS } from "../config";

export const onSliderLazy = {
  loaded: (ev, slick, $img) => {
    $img.addClass(CLASS_LAZY_SUCCESS);
  },
  error: (ev, slick, $img) => {
    $img.parent().addClass(CLASS_LAZY_ERROR);
  }
};

export const initSlider = ($element, config) => {
  return $element
    .slick(config)
    .on("lazyLoaded", onSliderLazy.loaded)
    .on("lazyLoadError", onSliderLazy.error);
};
