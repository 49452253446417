import $ from "jquery";
import "slick-carousel";
import { $html, $document } from "../config";
import Component from "./_base";
import MenuDesktopMega from "./MenuDesktopMega";

export default class MenuDesktop extends Component {
  static rootSelector = ".MenuDesktop";

  $onInit() {
    this.classOpen = "MenuDesktop--open";
    // needs to be `active` instead of `open` because that is whaat the
    // `delicious-hamburger` package needs
    this.classItemOpen = "active";
    this.classSlideready = "MenuDesktop--slideready";
    this.currentMenu = "";
    this.slidesMap = {};
    this.isOpen = false;
    this.lastScrollPosition = 0;

    this.menuMegaRobots = new MenuDesktopMega(".MenuDesktopMega--robots");
    this.menuMegaTech = new MenuDesktopMega(".MenuDesktopMega--technologies");
    this.menuMegaApplications = new MenuDesktopMega(
      ".MenuDesktopMega--applications"
    );
  }

  $onReady() {
    this.$header = $(".AppHeader");
    this.headerTopOffset = this.$header.height();
    this.$scroller = $("#Menu_scroller");
    this.$underneath = $("#Menu_underneath");
    this.$linksMega = $("[data-js='Menu.mega']");
    this.$linksSimple = $("[data-js='Menu.simple']");
    this.$links = $(this.$linksMega, this.$linksSimple);
    this.$megaMenu = this.$root.find(".MenuDesktop__mega");
    this.$megaMenu.appendTo(this.$scroller);
    this.$menus = this.$megaMenu.children();
    this.$slider = this.$megaMenu;

    this.bindHeaderLinks();
    this.bindOutsideClick();
    this.initslider();
  }

  bindHeaderLinks() {
    this.$linksSimple.on("click", () => {
      this.close();
    });

    this.$linksMega.each((idx, link) => {
      const target = link.getAttribute("data-target");
      const $menu = this.$menus.filter(`[data-js="${target}"]`);
      this.slidesMap[target] = this.$menus.index($menu);

      $(link).on("click", event => {
        const isOpen = this.toggle({ target, blockVerticalScroll: true });
        this.resetLinksStatus();

        if (isOpen) {
          $(event.target).addClass(this.classItemOpen);
        }
      });
    });
  }

  bindOutsideClick() {
    $document.mouseup(e => {
      if (this.isOpen) {
        if (!this.$root.is(e.target) && this.$root.has(e.target).length === 0) {
          this.close();
        }
      }
    });
  }

  initslider() {
    this.$slider.slick({
      adaptiveHeight: true,
      arrows: false,
      waitForAnimate: false,
      swipe: false,
      touchMove: false
    });

    // we can't use the slick `init` event because that triggers immediately
    // without waiting for the adaptiveHeight transition to be ended, so we just
    // use a hacky setTimeout...
    // this.$slider.on("init", () => {})
    setTimeout(() => {
      $html.addClass(this.classSlideready);
    }, 300);
  }

  resetLinksStatus() {
    this.$links.removeClass(this.classItemOpen);
  }

  open(target) {
    const wasItAlreadyOpen = !this.isOpen;
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    this.isOpen = true;
    this.currentMenu = target;
    this.lastScrollPosition = $document.scrollTop();

    // lock the position of the cntent underneath the menu in the right position
    this.$underneath.css({
      top: -(this.lastScrollPosition - this.headerTopOffset),
      // scrollbar width
      right: scrollbarWidth
    });

    // correct header position too according to the scrollbar
    this.$header.css("paddingRight", scrollbarWidth);

    // now add class that manages the menu stuff through CSS
    $html.addClass(this.classOpen);

    // scroll to top to get to the top of the menu
    $document.scrollTop(0);

    // the third argument is dontAnimate
    this.$slider.slick("slickGoTo", this.slidesMap[target], wasItAlreadyOpen);

    this.menuMegaRobots.onOpen();
    this.menuMegaTech.onOpen();
    this.menuMegaApplications.onOpen();
  }

  close() {
    this.isOpen = false;
    this.currentMenu = "";

    // reset style/layout changes
    $html.removeClass(this.classOpen);
    this.$underneath.css("top", 0);
    this.$header.css("paddingRight", "inherit");
    $document.scrollTop(this.lastScrollPosition);

    this.resetLinksStatus();
  }

  toggle({ target, blockVerticalScroll }) {
    if (this.isOpen && this.currentMenu === target) {
      this.close();
      return false;
    } else {
      this.open(target);
      return true;
    }
  }
}
