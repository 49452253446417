import $ from "jquery";

/**
 * Base component class to extend, it makes sure that the root selector
 * is in the page before initializing the component.
 */
export default class Component {
  static rootSelector = "";

  constructor(selectorOrElement) {
    if (!selectorOrElement && !this.constructor.rootSelector) {
      return;
    }

    if (selectorOrElement instanceof $) {
      this.$root = selectorOrElement;
    } else if (
      selectorOrElement instanceof HTMLElement ||
      typeof selectorOrElement === "string"
    ) {
      this.$root = $(selectorOrElement);
    } else if (this.constructor.rootSelector) {
      this.$root = $(this.constructor.rootSelector);
    }

    if (this.$root && this.$root.length) {
      this.$onInit();
      this.$onReady();
    }
  }

  $onInit() {}

  $onReady() {}
}
