import $ from "jquery";
import { $html } from "../config";
import Component from "./_base";

export default class MenuMobile extends Component {
  static rootSelector = ".MenuMobile";

  $onInit() {
    this.isOpen = false;
    this.classOpen = "MenuMobile--open";
    this.classToggler = "MenuMobile__toggler";
    this.classSubmenu = "MenuMobile__submenu";
    this.classBack = "MenuMobile__back";
    this.classHasChild = "hasChild";
    this.classActive = "active";
    this.tplBackBtn = $link =>
      `<li class="nav-item ${this.classBack}">
        <a class="nav-link" href="#">
          <i class="MenuMobile__iconPrev"></i>
          <span>${$link.text()}</span>
        </a>
      </li>`;
  }

  $onReady() {
    this.$toggler = this.$root.find(`.${this.classToggler}`);

    this.createBackButtons();
    this.bindEvents();
  }

  open(target) {
    this.isOpen = true;
    $html.addClass(this.classOpen);
  }

  close() {
    this.isOpen = false;
    $html.removeClass(this.classOpen);
  }

  toggle(target) {
    if (this.isOpen) {
      this.close();
    } else {
      this.open(target);
    }
  }

  createBackButtons() {
    const { $root, classSubmenu, classHasChild, tplBackBtn } = this;

    $root.find("ul." + classSubmenu).each(function() {
      const $subMenu = $(this);
      const $subMenuFirstItem = $subMenu.closest("li");
      const $subMenuFirstLink = $subMenuFirstItem.find("> a");
      const $subMenuBack = $(tplBackBtn($subMenuFirstLink));
      $subMenuFirstItem.addClass(classHasChild);
      $subMenuBack.prependTo($subMenu);
    });
  }

  bindEvents() {
    const {
      $root,
      classToggler,
      classHasChild,
      classBack,
      classActive,
      classSubmenu
    } = this;

    // toggler button
    this.$toggler.on("click", () => {
      this.toggle();
      this.$toggler.toggleClass("active");
    });

    // inner navigation links
    $root.find(`li.${classHasChild} > a`).click(function(event) {
      event.preventDefault();
      $root.scrollTop(0);
      $(this)
        .parent()
        .addClass(classActive)
        .siblings()
        .removeClass(classActive);
    });

    // back buttons links
    $root.find(`li.${classBack} a`).click(function(event) {
      event.preventDefault();
      $(this)
        .closest(`ul.${classSubmenu}`)
        .parent()
        .removeClass(classActive);
    });

    // lang menu toggler closes this menu
    $root.find(`button:not(.${classToggler})`).click(event => {
      this.close();
      // this.$toggler.toggleClass("active");
    });

    // all other links closes the menu
    $root
      .find(`li:not(.${classHasChild}):not(.${classBack}) > a`)
      .click(event => {
        this.close();
      });
  }
}
